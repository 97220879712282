import * as React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import CheckBox from "../components/CheckBox"
import Layout from "../components/Layout"
import TextInput from "../components/TextInput"
import { ErrorMessage, VisitData } from "../types/forms"
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import OptionsPicker from "../components/OptionsPicker"
import FormContainer from "../components/FormContainer"
import { timeOptions, visitDays } from "../data/schedule"
import { submitVisitData } from "../helpers/submitContactForm"
import { INPUT_MAX_LENGTH, MSG_MAX_LENGTH } from "../data/forms"
import { HeadFC } from "gatsby"

const CabinetVisitBookingPage = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Imię jest wymagane').max(INPUT_MAX_LENGTH, `Imię może mieć maks. ${INPUT_MAX_LENGTH} znaków`),
    phone: Yup.string().required('Numer telefonu jest wymagany').max(INPUT_MAX_LENGTH, `Telefon może mieć maks. ${INPUT_MAX_LENGTH} znaków`),
    message: Yup.string().max(MSG_MAX_LENGTH, `Wiadomość może mieć maks. ${MSG_MAX_LENGTH} znaków`),
    preferences: Yup.array(),
    consent: Yup.bool().oneOf([true], 'Zgoda jest wymagana'),
    recaptchaToken: Yup.string().required('To pole jest wymagane')
  });

  const onSubmit = async (data: VisitData) => await submitVisitData(data);

  return (
    <Layout pageTitle="Umów się na wizytę w gabinecie">
      <FormContainer onSubmit={onSubmit} resolver={yupResolver(validationSchema)} recaptchaField="recaptchaToken">
        {{
          header: (
            <>
              <h2 className="text-2xl font-medium text-gray-900">Example form</h2>
              <p className="mt-1 text-xl text-gray-600">
              Do not enter your real data here.
              </p>
            </>
          ),
          body: (register, errors) => (
            <>
              <div className="space-y-6 sm:w-2/3">
                <TextInput name="name" label="Imię i nazwisko" autoComplete="name" required {...{ register, errors }}/>
                <TextInput name="phone" label="Numer telefonu" autoComplete="tel" required {...{ register, errors }}/>
              </div>
              <TextInput as="textarea" name="message" label="Wiadomość" rows={4} placeholder="Rodzaj dolegliwości, dodatkowe informacje" {...{ register, errors }}/>
              <OptionsPicker name="preferences" masterSlot={visitDays} detailSlot={timeOptions} { ...{ register }}>
                Wybierz dogodną godzinę
              </OptionsPicker>
              <CheckBox name="consent" required {...{ register, errors }}>
                Wyrażam zgodę na przetwarzanie danych osobowych
              </CheckBox>
            </>
          ),
          footer: <span className="text-sm text-gray-500">Oddzwonimy i umówimy się na wizytę</span>
        }}
      </FormContainer>
    </Layout>
  )
}

export default CabinetVisitBookingPage